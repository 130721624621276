import React, { useEffect, useState } from "react";
import "../css/wb_mis_report.css";
import Import from "../Assets/images/import.png";
import Export from "../Assets/images/export.png";
import Search from "../Assets/images/search.png";
import { apiCall } from "../services/authServieces"; // Assuming you have this service

const WbMisReport = () => {
  const [reportData, setReportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [showPagi, setShowPagi] = useState(false);
  const itemsPerPage = 10;

  const fetchData = async (page) => {
    try {
      const params = new URLSearchParams({
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
        page: page,
        limit: itemsPerPage,
      });

      const res = await apiCall({
        endpoint: `report/getMisReportData?${params.toString()}`,
        method: "GET",
      });

      const transformedData = res.data.map(item => ({
        date: item.date,
        values: [item.total, item.delivered, item.failed, item.hsm_seen, item.session_send, item.session_delivered, item.session_failed, item.session_seen],
        imageSrc: Import,
      }));

      setReportData(transformedData);
      setTotalPages(Math.ceil(res.total / itemsPerPage));
      setShowPagi(res.total > itemsPerPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData(1);
  };
  

  const createPagination = () => {
    const pagesToShow = 1;
    let pages = [];

    if (totalPages > 1) {
      pages.push(1);
      if (currentPage > pagesToShow + 2) pages.push(null);

      for (let i = Math.max(2, currentPage - pagesToShow); i <= Math.min(totalPages - 1, currentPage + pagesToShow); i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - pagesToShow - 1) pages.push(null);
      pages.push(totalPages);
    }

    return pages;
  };

  const handleExport = () => {
    const headers = ['Sent Date', 'HSM Send', 'HSM Delivered', 'HSM Failed', 'HSM Seen', 'Session Send', 'Session Delivered', 'Session Failed', 'Session Seen'];
    const csvRows = [];

    csvRows.push(headers.join(','));

    reportData.forEach((item) => {
      const row = [
        item.date,
        ...item.values,
      ];
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'mis_report.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = async (date) => {
    try {

      const headers = ['Sent Date', 'HSM Send', 'HSM Delivered', 'HSM Failed', 'HSM Seen', 'Session Send', 'Session Delivered', 'Session Failed', 'Session Seen'];
      const csvRows = [];
  
      csvRows.push(headers.join(','));
      
  
      const res = await apiCall({
        endpoint: `report/download/date?date=${date}`,
        method: "GET",
      });
  
      res.data.forEach((item) => {
        const row = [
          item.date,
          item.total, 
          item.delivered, 
          item.failed, 
          item.hsm_seen, 
          item.session_send, 
          item.session_delivered, 
          item.session_failed, 
          item.session_seen
        ];
        csvRows.push(row.join(','));
      });

      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'mis_report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  

  return (
    <>
      <div className="Mis_report_contian">
        <h4 className="Head_title">MIS Report</h4>
        <div className="Mis_report_Card122">
          <div className="Date_contain">
            <div className="form_group221">
              <label htmlFor="from_date">From Date</label>
              <input
                type="date"
                id="from_date"
                className="datepicker"
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="form_group221">
              <label htmlFor="to_date">To Date</label>
              <input
                type="date"
                id="to_date"
                className="datepicker"
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="form_group221">
              <button type="button" onClick={handleSearch}>
                <img src={Search} alt="Search" /> Submit
              </button>
            </div>
            <div className="form_group221">
              <button type="button" className="export_btn" onClick={handleExport}>
                <img src={Export} alt="Export" /> Export
              </button>
            </div>
          </div>
          <div className="table_contain tMisReport">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>Sent Date</th>
                  <th>HSM Send</th>
                  <th>HSM Delivered</th>
                  <th>HSM Failed</th>
                  <th>HSM Seen</th>
                  <th>Session Send</th>
                  <th>Session Delivered</th>
                  <th>Session Failed</th>
                  <th>Session Seen</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    {item.values.map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                    <td className="download_icon">
                      <button>
                        <img src={item.imageSrc} alt="Import" onClick={() => handleDownload(item.date)} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showPagi && (
          <div className="pagination">
            {createPagination().map((page, index) => (
              page === null ? (
                <span key={index} className="ellipsis">...</span>
              ) : (
                <button
                  key={index}
                  className={`page-button ${currentPage === page ? 'active' : ''}`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              )
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WbMisReport;
