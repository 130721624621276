import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState, useEffect } from "react";
import { apiCall } from "../services/authServieces";
import ShowSnackBar from "../components/snackBar";
import IconButton from '@mui/material/IconButton';
import { getRandomNum } from "../utils/helper"; 
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from '@mui/material/MenuItem';
import Plus from "../Assets/images/plus.png";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../css/wb_manage_groups.css";
import Menu from '@mui/material/Menu';

const WbManageGroups = () => {
  const addGroupValues = { groupId: "", groupTitle: "", groupName: "", mobile: "" };
  const [snackBar, setSnackBar] = useState({open: false, severity: true,message: ""});
  const [formValues, setFormValues] = useState(addGroupValues);
  const [showExistModal, setShowExistModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [errors, setErrors] = useState({});
  const [count, setCount] = useState(0)
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuAction = (action) => {
    console.log(`${action} action on row: `, selectedRow);
    handleClose();
  };

  const handleChangePage = async (event, newPage) => {
    const updatedPage = newPage + 1; 
    await fetchGroupsList(updatedPage, rowsPerPage); 
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = async(event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchGroupsList(page, parseInt(event.target.value))
  };
  
  const columns = [
                    { id: "groupid", label: "Group Id" },
                    { id: "grouptitle", label: "Group Title" },
                    { id: "groupname", label: "Group Name" },
                    { id: "mobile", label: "Mobile" },
                    { id: "action", label: "Action" },
                  ];
  
  const fetchGroupsList = async (page=1, limit=10) => {
    try {
      const res = await apiCall({
        endpoint: `groups/get-groups?page=${page}&limit=${limit}`,
        method: "GET",
      });
      if (res?.success) {
        setRows(res?.data || []);
        setCount(res?.total)
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: false,
        message: err?.response?.data?.message || "An error occurred",
      });
    }
  };

  const fetchGroups = async () => {
    try {
      const res = await apiCall({
        endpoint: "groups/drop-down",
        method: "GET",
      });
      if (res?.success) {
        setGroupOptions(res?.data || []);
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: false,
        message: err?.response?.data?.message || "An error occurred",
      });
    }
  };

  useEffect(() => {
    
    fetchGroups();
    fetchGroupsList();
  },[]);

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.groupTitle.trim()) {
      formErrors.groupTitle = "Group title is required";
    }
    if (!formValues.groupName.trim()) {
      formErrors.groupName = "Group name is required";
    }
    if (!formValues.mobile.trim()) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formValues.mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const openAddModal = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      groupId: getRandomNum(),
    }));
    setShowAddModal(true);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmitOnExists = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm() && (!formValues.mobile || !formValues.groupId)) {
      setLoading(false);
      return;
    }
    try {
      const payload = {
        groupId: formValues.groupId,
        mobile: formValues.mobile,
      };
      const res = await apiCall({
        endpoint: "groups/add-to-existing",
        method: "POST",
        payload: payload,
      });
      setSnackBar({
        open: true,
        severity: res?.success,
        message: res?.message,
      });

      if (res?.success) {
        await fetchGroupsList()
        await fetchGroups()
        setFormValues(addGroupValues);
        setShowExistModal(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSnackBar({
        open: true,
        severity: err?.response?.data?.success,
        message: err?.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    try {
      const res = await apiCall({
        endpoint: "groups/add-groups",
        method: "POST",
        payload: formValues,
      });
      setSnackBar({
        open: true,
        severity: res?.success,
        message: res?.message,
      });

      if (res?.success) {
        await fetchGroupsList()
        await fetchGroups()
        setFormValues(addGroupValues);
        setShowAddModal(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSnackBar({
        open: true,
        severity: err?.response?.data?.success,
        message: err?.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <>
      <div className="Manage_groups_contian">
        <h4 className="Head_title3">
          Manage Groups
          <button className="add_btn4" 
    data-bs-toggle="modal"
    data-bs-target="#modal1">
            <img src={Plus} alt="img" /> New
          </button>
          <button className="exist_btn" 
    data-bs-toggle="modal"
    data-bs-target="#modal2">
            <img src={Plus} alt="img" /> Exist
          </button>
        </h4>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }} className="table_contain mt-3">
            <Table stickyHeader aria-label="sticky table" className="Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
      {rows.map((row) => (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align}>
                {column.id === "action" ? (
                  <>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, row)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleMenuAction("Edit")}>Edit</MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Delete")}>Delete</MenuItem>
                    </Menu>
                  </>
                ) : column.format && typeof value === "number" ? (
                  column.format(value)
                ) : (
                  value
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      
      <div
  className="modal fade"
  id="modal1"
  tabindex="-1"
  aria-labelledby="Preview_modal_Label"
  aria-hidden="true"
        >
          <div className="modal-dialog maxW400">
              <div className="modal-header">
              <h5 className="modal-title text-center w-100" id="Preview_modal_Label">Add Campaign</h5>
              </div>
            <div className="modal-content">
              <form onSubmit={handleSubmit}>
                <div className="modal-body p-0">
                  
        <div className="Modal_Table_contain">
          <div className="FormGroup">
            <label htmlFor="group_id">Group ID <span className="required_icon">*</span></label>
            <input type="text" id="group_id"
                            name="groupId"
                            placeholder="Enter Group ID"
                            value={formValues.groupId}
                            readOnly
                          />
          </div>
          <div className="FormGroup">
            <label htmlFor="group_title">Group Title <span className="required_icon">*</span></label>
            <input
                            type="text"
                            id="group_title"
                            name="groupTitle"
                            placeholder="Enter Group Title"
                            value={formValues.groupTitle}
                            onChange={handleChanges}
                          />
                          {errors.groupTitle && (
                            <span className="FormGroupError">{errors.groupTitle}</span>
                          )}
          </div>
          <div className="FormGroup">
            <label htmlFor="group_name">Group Name <span className="required_icon">*</span></label>
            <input
                            type="text"
                            id="group_name"
                            name="groupName"
                            placeholder="Enter Group Name"
                            value={formValues.groupName}
                            onChange={handleChanges}
                          />
                          {errors.groupName && (
                            <span className="FormGroupError">{errors.groupName}</span>
                          )}
          </div>
          <div className="FormGroup">
            <label htmlFor="mobile">Mobile <span className="required_icon">*</span></label>
            <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            placeholder="Enter Mobile"
                            value={formValues.mobile}
                            onChange={handleChanges}
                          />
                          {errors.mobile && (
                            <span className="FormGroupError">{errors.mobile}</span>
                          )}
          </div>
          <div className="modal-buttons">
          <button className="Modal_save_btn"
                              type="submit"
                            disabled={loading} // Disable button when loading
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              >
                                Saving...
                              </span>
                            ) : (
                              "Save"
                            )}
                            </button>
             <button type="button" className="Modal_cancel_btn ms-2" 
          data-bs-dismiss="modal"
          aria-label="Close">Cancel</button>
            </div>
    </div>
               
                </div>
              </form>
            </div>
          </div>
        </div>


           <div
  className="modal fade"
  id="modal2"
  tabindex="-1"
  aria-labelledby="Preview_modal_Label"
  aria-hidden="true"
        >
          <div className="modal-dialog aria-modal maxW400">
              <div className="modal-header">
                <h5 className="modal-title text-center w-100" id="Exist_btn_modal_Label">
                  Add In Exist Group
                </h5>
                {/* <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowExistModal(false);
                    setFormValues(addGroupValues);
                    setErrors({});
                  }} // Close modal
                  aria-label="Close"
                ></button> */}
              </div>
            <div className="modal-content">
              <form onSubmit={handleSubmitOnExists}>
                <div className="modal-body p-0">
                  <div className="Modal_Table_contain">
          <div className="FormGroup">
            <label htmlFor="headerid">Group Name <span className="required_icon">*</span></label>
            
                            <select
                              id="headerid"
                              value={formValues.groupId}
                              onChange={handleChanges}
                              name="groupId"
                            >
                              <option value="">Select Group Name</option>

                              {groupOptions.map((group) => (
                                <option key={group.id} value={group.groupid}>
                                  {group.groupname}
                                </option>
                              ))}
                            </select>
                            {errors.groupId && (
                              <span className="FormGroupError">{errors.groupId}</span>
                            )}
          </div>
          <div className="FormGroup">
          <label htmlFor="mobile">Mobile <span className="required_icon">*</span></label>
                            <textarea
                              name="mobile"
                              id="mobile"
                              value={formValues.mobile}
                              onChange={handleChanges}
                              rows="3"
                              placeholder="Enter Mobile"
                            ></textarea>
                            {errors.mobile && (
                              <span className="FormGroupError">{errors.mobile}</span>
                            )}
          </div>
                    <div className="modal-buttons">
          <button className="Modal_save_btn"
                              type="submit"
                              disabled={loading} // Disable button when loading
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                >
                                  Saving...
                                </span>
                              ) : (
                                "Save"
                              )}
                            </button>
             <button type="button" className="Modal_cancel_btn ms-2" 
          data-bs-dismiss="modal"
          aria-label="Close">Cancel</button>
            </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default WbManageGroups;
