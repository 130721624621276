import React from "react";
import "./../../css/dashboard.css";
import TodayCard from "../../components/TodayCard";
import Total from "../../Assets/images/total.png";
import Delivered from "../../Assets/images/delivered.png";
import Failed from "../../Assets/images/failed.png";
import Read from "../../Assets/images/read.png";
import Send from "../../Assets/images/send.png";
import Receive from "../../Assets/images/recieve.png";
import ChartComponent from "../../components/ChartComponent";
import BarData from "../../components/sidebar/BarData";




const AdminDashboard = () => {
  return (
    <>
      <div className="Dashboard_contain">
        <h4 className="Head_title">
          Admin Dashboard <span>Business Whatsapp</span>
        </h4>

        <div className="Dashboard_card">
          <h5>HSM Dashboard</h5>

          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_submission"
                data="0"
                header="Total HSM"
                src={Total}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_delivered"
                data="0"
                header="Total Delivered "
                src={Delivered}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_failed"
                data="0"
                header="Today Failed "
                src={Failed}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <TodayCard
                className="Today_card_img bg_pending"
                data="0"
                header="HSM Read"
                src={Read}
              />
            </div>
          </div>
          <div className="border_top">
            <h5>Session Dashboard</h5>
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_submission"
                  data="0"
                  header="Total Session"
                  src={Total}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_delivered"
                  data="0"
                  header="Send Session"
                  src={Send}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_failed"
                  data="0"
                  header="Total Receive Session"
                  src={Receive}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <TodayCard
                  className="Today_card_img bg_pending"
                  data="0"
                  header="Session Read"
                  src={Read}
                />
              </div>
            </div>
          </div>

          <div className="Recharge_section">
            <div className="row gy-4">
              <div className="col-lg-5">
                <div className="Today_bar">
                  <BarData title="Today HSM Delivery" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="Today_bar">
                  <ChartComponent title="HSM Report (Last 10 days)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
