import Plus from "../Assets/images/plus.png";
import "../css/wb_manage_block_number.css";
import React, { useEffect, useState } from "react";
import { apiCall } from "../services/authServieces";
import ShowSnackBar from "../components/snackBar";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from '@mui/material/MenuItem';

import { formatDate } from "../utils/helper";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../css/wb_manage_groups.css";
import Menu from '@mui/material/Menu';


const WbManageBlockNumber = () => {
  const forms = { mobile: "", description: "" };
  const [formValues, setForms] = useState(forms);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0)
  const [page, setPage] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);




  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: true,
    message: "",
  });
  const [rows, setRows] = useState([]);




  const fetchNumberList = async (page=1, limit=10) => {
    try {
      const res = await apiCall({
        endpoint: `block/get-numbers?page=${page}&limit=${limit}`,
        method: "GET",
      });
      if (res?.success) {
        setRows(res?.data || []);
        setCount(res?.total)
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: false,
        message: err?.response?.data?.message || "An error occurred",
      });
    }
  };

  useEffect(()=>{
    fetchNumberList()
  },[])

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };


  const handleChangePage = async (event, newPage) => {
    const updatedPage = newPage + 1; 
    await fetchNumberList(updatedPage, rowsPerPage); 
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = async(event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchNumberList(page, parseInt(event.target.value))
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const [anchorEl, setAnchorEl] = useState(null);


  const columns = [
    { id: "srNum", label: "Sr. No" },
    { id: "cdate", label: "Block Date" },
    { id: "block_number", label: "Mobile" },
    { id: "description", label: "Description" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setForms({ ...formValues, [name]: value });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuAction = (action) => {
    console.log(`${action} action on row: `, selectedRow);
    handleClose();
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formValues.mobile.trim()) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formValues.mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!formValues.description.trim()) {
      formErrors.description = "Description is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    try {
      const res = await apiCall({
        endpoint: "block/block-number",
        method: "POST",
        payload: formValues,
      });
      if (res?.success) {
        setForms(forms);
        setLoading(false);
        setSnackBar({
          open: true,
          severity: res?.success,
          message: res?.message,
        });
        setShowModal(false);
      }
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      setSnackBar({
        open: true,
        severity: error?.response?.data?.success,
        message: error?.response?.data?.message || "An error occurred",
      });
    }
  };

  const openModal = () => {
    setShowModal(true); // Show modal
  };

  const closeModal = () => {
    setErrors({});
    setShowModal(false); 
  };

  return (
    <>
      <div className="Block_number_contian">
        <h4 className="Head_title">
          Manage Block Mobile
          <button className="add_btn5" 
    data-bs-toggle="modal"
    data-bs-target="#Preview_modal">
            <img src={Plus} alt="img" /> New
          </button>
        </h4>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }} className="table_contain">
            <Table stickyHeader aria-label="sticky table" className="Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value = row[column.id];
                       if (column.id === "srNum") {
                        value = index + 1;
                      }
                      if (column.id === "cdate") {
                        value = formatDate(row.cdate);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "action" ? (
                            <>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => handleMenuAction("Edit")}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleMenuAction("Delete")}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <div
  className="modal fade"
  id="Preview_modal"
  tabindex="-1"
  aria-labelledby="Preview_modal_Label"
  aria-hidden="true"
        >
          <div className="modal-dialog maxW400">
              <div className="modal-header">
              <h5 className="modal-title text-center w-100" id="Preview_modal_Label">Add Campaign</h5>
              </div>
            <div className="modal-content">
              <form onSubmit={handleOnSubmit}>
                <div className="modal-body p-0">
                  
        <div className="Modal_Table_contain">
          <div className="FormGroup">
            <label htmlFor="mobile">Mobile <span className="required_icon">*</span></label>
           
            <input
                              type="text"
                              id="mobile"
                              placeholder="Enter Mobile"
                              name="mobile"
                              value={formValues.mobile}
                              onChange={handleChanges}
                            />
                            {errors.mobile && (
                              <span className="FormGroupError">{errors.mobile}</span>
                            )}
          </div>
          <div className="FormGroup">
            <label htmlFor="des">Description <span className="required_icon">*</span></label>

            <textarea
                              id="des"
                              rows="3"
                              placeholder="Enter Description"
                              name="description"
                              value={formValues.description}
                              onChange={handleChanges}
                            ></textarea>
                            {errors.description && (
                              <span className="FormGroupError">
                                {errors.description}
                              </span>
                            )}
          </div>
          <div className="modal-buttons">
          <button
                              type="submit"
                              className="Modal_save_btn"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <span className="ms-2">Saving</span>{" "}
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                "Save"
                              )}
                            </button>
             <button type="button" className="Modal_cancel_btn ms-2" 
          data-bs-dismiss="modal"
          aria-label="Close">Cancel</button>
            </div>
    </div>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default WbManageBlockNumber;
