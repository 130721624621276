import React from "react";
import "../css/wb_developers_tools.css";
import UrlCard from "../components/UrlCard";

const WbDevelopersTools = () => {
  return (
    <>
      <div className="Developers_tools_contain">
        <h4 className="Head_title">Send Whatsapp HTTP API :</h4>
        <div className="Developers_tools_card">
          <p>
            API, An Abbreviation of Application Promgramme Interface, is a set
            pf routine, protocols and tools for building software applications.
            Whatsapp API allows application developer to integrate on there
            application and send the sms to all the numbers at one shot without
            login to their sms panel.
          </p>
          <div className="table_contain">
            <table className="Table">
              <thead>
                <tr>
                  <th>
                    <button>Generate API Key</button>
                  </th>
                  <th>API Key</th>
                  <th>API Password</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" id="otp" placeholder="Enter Your OTP" />
                  </td>
                  <td>@123</td>
                  <td>#123</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="url_contain">
            <h4 className="cotain_title">JSON API :</h4>
            <UrlCard
              header="Send Text Whatsapp API :"
              url="https://easywpbot.com/api/send_text_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX", "muldealer_code":"XXXXXXX", "template_id":"XXXX", "mobile":"91XXXXXX", "type":"template", "sample":"abc,xyz"'
            />

            <UrlCard
              header="Send Document Whatsapp API :"
              url="https://easywpbot.com/api/send_doc_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "filename":"XXXX",
                "type":"template", "sample":"abc,xyz"'
            />

            <UrlCard
              header="Send Document Whatsapp API :"
              url="https://easywpbot.com/api/send_doc_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "filename":"XXXX",
                "type":"template", "sample":"abc,xyz"'
            />

            <UrlCard
              header="Send Image Whatsapp API :"
              url="https://easywpbot.com/api/send_img_sms.php"
              title="Sample POST Request"
              api=' "api_key":"XXXXXXX", "pass":"XXXXXXX",
                "muldealer_code":"XXXXXXX", "template_id":"XXXX",
                "mobile":"91XXXXXX", "url":"https:XXXXX", "type":"template",
                "sample":"abc,xyz"'
            />

            <UrlCard
              header="Send Video Whatsapp API :"
              url="https://easywpbot.com/api/send_video_sms.php"
              title="Sample POST Request"
              api='"api_key":"XXXXXXX", "pass":"XXXXXXX",
              "muldealer_code":"XXXXXXX", "template_id":"XXXX",
              "mobile":"91XXXXXX", "url":"https:XXXXX", "type":"template",
              "sample":"abc,xyz"'
            />

            <div className="url_card">
              <h4>Session Image Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_image_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "type":"image"
              </p>
            </div>

            <div className="url_card">
              <h4>Session Document Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_doc_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "filename":"XXXXXX", "type":"document"
              </p>
            </div>
            <div className="url_card">
              <h4>Session Video Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_video_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "filename":"XXXXXX", "type":"video"{" "}
              </p>
            </div>
            <div className="url_card">
              <h4>Session Audio Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/session_audio_sms.php</p>
              <h6>Sample POST Request</h6>
              <p>
                "api_key":"XXXXXXXX", "pass":"XXXXXX", "mobile":"91XXXXX",
                "url":"https://XXXXXX", "type":"audio"{" "}
              </p>
            </div>
            <div className="url_card border-0">
              <h4>Balance Check API :</h4>
              <p>
                https://easywpbot.com/api/balance_check.php?api_key=xxxxxxxxx&pass=xxxxxxx
              </p>
            </div>
            <h4 className="cotain_title">URL API :</h4>
            <div className="url_card">
              <h4>Send Text Whatsapp URL API :</h4>
              <p>URL:https://easywpbot.com/api/sendtext_url_api.php?</p>
              <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&type=template&sample=abc,xyz
              </p>
            </div>
            <div className="url_card">
              <h4>Send Document Whatsapp URL API :</h4>
              <p>URL:https://easywpbot.com/api/senddoc_url_sms.php?</p>
              <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&url=https:XXXXX&filename=XXXX&type=template&sample=abc,xyz
              </p>
            </div>
            <div className="url_card">
              <h4>Send Image Whatsapp API :</h4>
              <p>URL:https://easywpbot.com/api/send_img_url_api.php?</p>
              <p>
                api_key=XXXXXXX&pass=XXXXXXX&muldealer_code=XXXXXX&template_id=XXXX&mobile=91XXXXXX&url=https:XXXXX&type=template&sample=abc,xyz
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WbDevelopersTools;
