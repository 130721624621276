import { React, useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../css/wb_add_template.css";
import Smile from "../Assets/images/smile.png";
import Bpng from "../Assets/images/b.png";
import Italic from "../Assets/images/italic.png";
import User from "../Assets/images/user.png";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { prepareFormData, postTemplate } from "../services/templateService";
import ShowSnackBar from "../components/snackBar";
import languages from "@cospired/i18n-iso-languages";
import EmojiPicker from "emoji-picker-react";
import SplitButton from "../components/SplitGroupBtn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ReplyIcon from "@mui/icons-material/Reply";
import LaunchIcon from '@mui/icons-material/Launch';
import { apiCall } from '../services/authServieces';
import { validationSchema } from "../utils/formValidation";
import { ClipLoader } from 'react-spinners';

languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));

const WbAddTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState('');
  const [maxCharacters] = useState(800);
  const [charactersUsed, setCharactersUsed] = useState(0);
  const [templateName, isTemplateName] = useState("");
  const [templateType, isTemplateType] = useState("Text");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [selectedVar, setSelectedVar] = useState("");
  const [variableValue, setVariableValue] = useState("");
  const [templateMessage, setTemplateMessage] = useState("");
  const [variables, setVariables] = useState({});
  const [footerVal, setFooterVal] = useState("");
  const [redirectLink, setRedirectLink] = useState(false);
  const [isCategory, setIsCategory] = useState("Marketing");
  const [isLanguage, setIsLanguage] = useState("English");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: true,
    message: "",
  });
  const [selectedMedia, setSelectedMedia] = useState("");
  const textareaRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [showCallToAction, setShowCallToAction] = useState(false);
  const [showCallPhone, setShowCallPhone] = useState(false);
  const [showQuickReply, setShowQuickReply] = useState(false);
  const [visitWebsiteText, setVisitWebsiteText] = useState("");
  const [domain, setDomain] = useState("");
  const [additionalUrls, setAdditionalUrls] = useState([]);
  const [phoneNumberText, setPhoneNumberText] = useState("");
  const [phone, setPhone] = useState("");
  const [quickReply, setQuickReply] = useState("");
  const [isNewQuickReply, setIsNewQuickReply] = useState([]);
  const [totalButtons, setTotalButtons] = useState(0);
  const [inputFields, setInputFields] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState("");
  const [placeholderVariables, setPlaceHolderVariables] = useState([]);

  const languages = [
    { value: "", label: "--Select--" },
    { value: "af", label: "Afrikaans" },
    { value: "sq", label: "Albanian" },
    { value: "ar", label: "Arabic" },
    { value: "az", label: "Azerbaijani" },
    { value: "bn", label: "Bengali" },
    { value: "bg", label: "Bulgarian" },
    { value: "ca", label: "Catalan" },
    { value: "zh_CN", label: "Chinese (CHN)" },
    { value: "zh_HK", label: "Chinese (HKG)" },
    { value: "zh_TW", label: "Chinese (TAI)" },
    { value: "hr", label: "Croatian" },
    { value: "cs", label: "Czech" },
    { value: "da", label: "Danish" },
    { value: "nl", label: "Dutch" },
    { value: "en", label: "English" },
    { value: "en_GB", label: "English (UK)" },
    { value: "en_US", label: "English (US)" },
    { value: "et", label: "Estonian" },
    { value: "fil", label: "Filipino" },
    { value: "fi", label: "Finnish" },
    { value: "fr", label: "French" },
    { value: "de", label: "German" },
    { value: "el", label: "Greek" },
    { value: "gu", label: "Gujarati" },
    { value: "he", label: "Hebrew" },
    { value: "hi", label: "Hindi" },
    { value: "hu", label: "Hungarian" },
    { value: "id", label: "Indonesian" },
    { value: "ga", label: "Irish" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "kn", label: "Kannada" },
    { value: "kk", label: "Kazakh" },
    { value: "ko", label: "Korean" },
    { value: "lo", label: "Lao" },
    { value: "lv", label: "Latvian" },
    { value: "lt", label: "Lithuanian" },
    { value: "mk", label: "Macedonian" },
    { value: "ms", label: "Malay" },
    { value: "mr", label: "Marathi" },
    { value: "nb", label: "Norwegian" },
    { value: "fa", label: "Persian" },
    { value: "pl", label: "Polish" },
    { value: "pt_BR", label: "Portuguese (BR)" },
    { value: "pt_PT", label: "Portuguese (POR)" },
    { value: "pa", label: "Punjabi" },
    { value: "ro", label: "Romanian" },
    { value: "ru", label: "Russian" },
    { value: "sr", label: "Serbian" },
    { value: "sk", label: "Slovak" },
    { value: "sl", label: "Slovenian" },
    { value: "es", label: "Spanish" },
    { value: "es_AR", label: "Spanish (ARG)" },
    { value: "es_ES", label: "Spanish (SPA)" },
    { value: "es_MX", label: "Spanish (MEX)" },
    { value: "sw", label: "Swahili" },
    { value: "sv", label: "Swedish" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "th", label: "Thai" },
    { value: "tr", label: "Turkish" },
    { value: "uk", label: "Ukrainian" },
    { value: "ur", label: "Urdu" },
    { value: "uz", label: "Uzbek" },
    { value: "vi", label: "Vietnamese" },
    { value: "ka", label: "Georgian" },
    { value: "ha", label: "Hausa" },
    { value: "rw_RW", label: "Kinyarwanda" },
    { value: "ky_KG", label: "Kyrgyz (Kyrgyzstan)" },
    { value: "ml", label: "Malayalam" }
  ];

  const cancelNavigate = useNavigate();
  

  
  const handleInputChange = (index, event) => {
    const newVariables = [...placeholderVariables];
    newVariables[index] = event.target.value;
    setPlaceHolderVariables(newVariables);
  };
  
  const handleOptionSelect = (option) => {
    if (option === "Visit Website") {
      if(totalButtons < 10){
        setShowCallToAction(true);
        setTotalButtons(totalButtons + 1);
      }else{
        setSnackBar({
          open: true,
          severity: false,
          message: 'Only 10 Buttons are Allowed'
        })
      }
    } else if (option === "Call Phone Number") {
      if(totalButtons < 10){
        setShowCallPhone(true);
        setTotalButtons(totalButtons + 1);
      }else{
        setSnackBar({
          open: true,
          severity: false,
          message: 'Only 10 Buttons are Allowed'
        })
      }
    } else if (option === "Quick Reply") {
      if(totalButtons < 10){
        setShowQuickReply(true);
        setTotalButtons(totalButtons + 1);
      }else{
        setSnackBar({
          open: true,
          severity: false,
          message: 'Only 10 Buttons are Allowed'
        })
      }
        
    }
  };

  const handleAddUrl = () => {
    if (totalButtons  < 10) { 
      setAdditionalUrls([
        ...additionalUrls,
        { id: Date.now(), buttonText: "", domain: "" },
      ]);
      setTotalButtons(totalButtons + 1);
    }else{
      setSnackBar({
        open: true,
        severity: false,
        message: "Only 10 Buttons are Allowed",
      });
    }
  };

  const handleNewReply = () => {
    if(totalButtons < 10){
      setIsNewQuickReply([
        ...isNewQuickReply,
        { id: Date.now(), buttonText: "" },
      ]);
      setTotalButtons(totalButtons + 1);
    }else{
      setSnackBar({
        open: true,
        severity: false,
        message: 'Only 10 Buttons are Allowed'
      })
    }
  };

  const handleRemoveUrl = (id) => {
    setAdditionalUrls(additionalUrls.filter((url) => url.id !== id));
    setTotalButtons(totalButtons - 1);
  };

  const onEmojiClick = (emojiObject) => {
    setTemplateMessage((prevMssg) => prevMssg + emojiObject.emoji);
    setShowPicker(false);
  };

  const checkValidInp = (e) => {
    let inpVal = e.target.value;
    inpVal = inpVal.toLowerCase().replace(/\s/g, '_');
    const regExpress = /^[0-9a-z_]*$/;
    if (regExpress.test(inpVal)) {
      isTemplateName(inpVal);
    }
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedVar(value);
  
    if (value === "") {
      setTemplateMessage("");
      setVariables({});
    } else {
      setTemplateMessage((prevValue) => `${prevValue}{{${value}}} `);
    }
  
    if (value && !inputFields.includes(value)) {
      setInputFields((prevFields) => [...prevFields, value]);
    }
  };
  

  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setTemplateMessage(newValue);

    setCharactersUsed(newValue.length);

    const regex = /\{\{(.*?)\}\}/g;
    const foundVars = [...newValue.matchAll(regex)].map((match) => match[1]);

    setInputFields((prevFields) =>
      prevFields.filter((field) => foundVars.includes(field))
    );

    setVariables((prevVars) => {
      const updatedVars = { ...prevVars };
      Object.keys(updatedVars).forEach((key) => {
        if (!foundVars.includes(key)) {
          delete updatedVars[key];
        }
      });
      return updatedVars;
    });
    setPlaceHolderVariables((prevPlaceholders) => {
      return prevPlaceholders.filter((_, index) => {
          return foundVars.includes((index + 1).toString());
      });
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    console.log(fileSize, ' got file size');
    const maxSize = 5 * 1024 * 1024;
    
    const fileUrl = URL.createObjectURL(file);
    if(fileSize > maxSize){
      setSnackBar({
        open: true,
        severity: false,
        message: `Media Size Can't Exceed limit of 5mb`
      })
      return event.target.value='';
    }
    if (file) {
      setFile(file);
      const url = URL.createObjectURL(file);
      setFileURL(url);
      setFileType(file.type);
    }
  };

  const isImage = fileType?.startsWith("image");
  const isVideo = fileType?.startsWith("video");
  const isDocument = fileType?.startsWith("application");

  const handleMouseUp = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const selectedText = templateMessage.slice(start, end);

    if (selectedText) {
      const modifiedText =
        templateMessage.slice(0, start) +
        `*${selectedText}*` +
        templateMessage.slice(end);
      setTemplateMessage(modifiedText);
    }
  };

  const handleMouseUpItalic = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const selectedText = templateMessage.slice(start, end);

    if (selectedText) {
      const modifiedText =
        templateMessage.slice(0, start) +
        `_${selectedText}_` +
        templateMessage.slice(end);
      setTemplateMessage(modifiedText);
    }
  };


  const handleSubmit = async (e) => {
    
    // Check if visitWebsiteText is not blank
    if (visitWebsiteText) {
      if (!validateURL(visitWebsiteText)) {
        setSnackBar({
          open: true,
          severity: false,
          message: "Please enter a valid URL.",
        });
        return;
      }
    }
  
    e.preventDefault();

      setLoading(true); 
      const formData ={
        templateName,
        isCategory,
        isLanguage,
        templateType,
        selectedMedia,
        fileType,
        fileURL,
        file,
        selectedVar,
        variableValue,
        templateMessage,
        footerVal,
        maxCharacters,
        charactersUsed,
        variables,
        visitWebsiteText,
        domain,
        additionalUrls,
        phoneNumberText,
        phone,
        quickReply,
        isNewQuickReply,
        inputFields,
        placeholderVariables,
      };
    
      try {
        
        let validatedObj= await validationSchema.validate(formData, { abortEarly: false });

        const res = await postTemplate({ payload: prepareFormData(validatedObj)});
        if (res.success) {
          setLoading(false);
          setSnackBar({
            open: true,
            severity: res?.success,
            message: res?.message,
          });
          setTimeout(() => {
            setRedirectLink(true);
          }, 1000);
        }
      } catch (err) {
        setLoading(false);
        if (err.name === 'ValidationError') {
          const validationErrors = err.inner.map((error) => error.message);
          setSnackBar({
            open: true,
            severity: false,
            message: validationErrors.join(', '),
          });
        } else {
          setSnackBar({
            open: true,
            severity: err?.response?.data?.success || false,
            message: err?.response?.data?.message || "An error occurred",
          });
        }
      }
    
  };
  
  
  const validateURL = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // domain name and extension
      'localhost|' + // OR localhost
      '\\d{1,3}(\\.\\d{1,3}){3})' + // OR IPv4 address
      '(\\:\\d+)?' + // optional port
      '(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)*' + // path
      '(\\?[;&a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // query string
      '(\\#[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$',
      'i'
    );
    return !!urlPattern.test(url);
  };

  // user info
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await apiCall({ endpoint: 'templates/getUserDetails', method: 'GET' });
  
        if (res?.success) {
          setUserInfo(res.userName);
        }
      } catch (error) {
        setSnackBar({
          open: true,
          severity: 'error',
          message: error?.response?.data?.message || "An error occurred",
        });
      }
    };
  
    getUserInfo();
  }, []); 

  useEffect(()=>{
    const convertToHTML = (message) => {
      let html = message;

      html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

      html = html.replace(/_(.*?)_/g, "<em>$1</em>");

      return html;
    };
    setFormattedMessage(convertToHTML(templateMessage));
  }, [templateMessage])

  if (redirectLink) {
    return <Navigate to="/wbtemplate" />;
  }
  
  return (
    <>
      <div className="WB_add_template_contain">
        <h4 className="Head_title">Add Template</h4>
        <div className="Customize_card">
          <div className="row">
            <div className="col-lg-7">
              <div className="row gy-3">
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="tname">
                      Template Name <span className="required_icon">*</span>
                    </label>
                    <input
                      type="text"
                      id="tname"
                      placeholder="Enter Template Name"
                      onChange={checkValidInp}
                      value={templateName}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="Category">
                      Category <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Category"
                      value={isCategory}
                      onChange={(e)=>{setIsCategory(e.target.value)}}
                    >
                      <option value="Marketing">Marketing</option>
                      <option value="Utility">Utility</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="form_group">
                    <label htmlFor="Language">
                      Language <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Language"
                      value={isLanguage}
                      onChange={(e) => setIsLanguage(e.target.value)}
                    >
                      {languages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  
                <p className="highlight">
                    Template name can only contain lowercase alphanumeric characters and underscores ( _ ). No other characters or white space are allowed.
                    </p>
                </div>
              </div>
              <div className="WB_template_header">
                <h5 className="commonHeading">Header Optional</h5>
                <p >
                  Add a title or choose which type of media you'll use htmlFor
                  this header. your title can't include more than one variable.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 margin_top0">
                  <div className="form_group">
                    <label htmlFor="Type">
                      Template Type <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Type"
                      value={templateType}
                      onChange={(e)=>{isTemplateType(e.target.value);}}
                    >
                      <option value="">--Select--</option>
                      <option value="Text">Text </option>
                      <option value="Media">Media</option>
                    </select>
                  </div>
                  <div className="row">
                <div
                  className="col-lg-12 margin_top0"
                  style={{
                    display: templateType === "Media" ? "block" : "none",
                  }}
                >
                  <div className="form_group">
                    <label htmlFor="mediaType">
                      Media Type <span className="required_icon">*</span>
                    </label>

                    <label htmlFor="Document" className="radio-inline">
                      <input
                        type="radio"
                        id="Document"
                        name="val"
                        value="Document"
                        checked={selectedMedia === "Document"}
                        onChange={(event)=>{setSelectedMedia(event.target.value)}}
                      />
                      Document
                    </label>

                    <label htmlFor="Images" className="radio-inline">
                      <input
                        type="radio"
                        id="Images"
                        name="val"
                        value="Image"
                        checked={selectedMedia === "Image"}
                        onChange={(event)=>{setSelectedMedia(event.target.value)}}
                      />
                      Images
                    </label>

                    <label htmlFor="Video" className="radio-inline">
                      <input
                        type="radio"
                        id="Video"
                        name="val"
                        value="Video"
                        checked={selectedMedia === "Video"}
                        onChange={(event)=>{setSelectedMedia(event.target.value)}}
                      />
                      Video
                    </label>
                    <p className="highlight">
                    Image: jpeg, png; Video: mp4; Document: .pdf, Maximum file size should be 5MB
                    </p>
                  </div>
                  <div className="form_group">
                    <label htmlFor="Media Upload">Upload Media</label>
                    <input
                      type="file"
                      placeholder="Upload Media"
                      onChange={handleFileChange}
                      name="media"
                    />
                  </div>
                </div>
              </div>
                </div>
                <div className="col-lg-6 col-12 margin_top0">
                {/* <div className="WB_template_header">
                <h5 className="commonHeading">Body</h5>
              </div> */}
                  <div className="form_group margin_top0">
                    <label htmlFor="Placeholder">
                      Variable <span className="required_icon">*</span>
                    </label>
                    <select
                      id="Placeholder"
                      value={selectedVar}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select</option>
                      <option value="1">Var 1 </option>
                      <option value="2">Var 2</option>
                      <option value="3">Var 3</option>
                      <option value="4">Var 4</option>
                      <option value="5">Var 5</option>
                      <option value="6">Var 6</option>
                      <option value="7">Var 7</option>
                      <option value="8">Var 8</option>
                      <option value="9">Var 9</option>
                      <option value="10">Var 10</option>
                    </select>
                  </div>
                </div>
              </div>

           

           
              <div className="row">
             
                <div className="col-lg-12 mt-3">
                  <div className="form_group">
                    <label htmlFor="Message">
                      Message: <span className="required_icon">*</span>
                    </label>
                    <span className="message_icons">
                      <button className="editingBtn"   onClick={() => {
    // Toggle the picker visibility
    setShowPicker((val) => !val);
    
    // Append the smiley emoji to the message
    setTemplateMessage((prevMessage) => prevMessage + '😊'); // Use the desired emoji
  }}
                      
                      
                        >
                        <img
                          src={Smile}
                          alt="img"
                          onClick={() => setShowPicker((val) => !val)}
                        />
                      </button>
                      {showPicker && (
                        <div className="emoji-picker-container">
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                      )}
                      <button className="B_icon_btn editingBtn" onMouseUp={handleMouseUp}>
                        <img src={Bpng} alt="img" />
                      </button>
                      <button className="editingBtn">
                        <img
                          src={Italic}
                          alt="img"
                          onMouseUp={handleMouseUpItalic}
                        />
                      </button>
                    </span>
                    <textarea
                      rows="3"
                      maxLength={800}
                      name="Message"
                      id="Message"
                      value={templateMessage}
                      onChange={handleTextareaChange}
                      placeholder="Enter Template Message"
                      ref={textareaRef}
                    ></textarea>
                  </div>
                  <div className="List_card">
                    <span>
                      <span>{charactersUsed}</span> Characters, <span>1</span>{" "}
                      SMS
                    </span>

                    <span>
                      {" "}
                      Max Characters : <span>{maxCharacters}</span>{" "}
                    </span>
                  </div>

                  <div className="col-lg-12 mt-3">
                    {inputFields.length > 0 && (
                      <>
                        <h6>Place Holder Value</h6>
                        {inputFields.map((field, index) => (
                          <div key={index} className="form_group">
                            <input type="text" className="mb-2" placeholder={`Enter Sample Value for Body {{${field}}}`} value={placeholderVariables[index] || ""}
                            onChange={(event) => handleInputChange(index, event)} />
                          </div>
                        ))}
                      </>
                    )}
                  
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="WB_template_header margin_top0 pb-0">
                    <h5 className="commonHeading FooterTitle">Footer !</h5>
                    <p className="FooterHintContent">
                      Add a short line of text to the bottom of your message
                      template{" "}
                    </p>
                  </div>
                  <div className="form_group">
                    {/* <label htmlFor="Text">
                      Enter Text: <span className="required_icon">*</span>
                    </label> */}
                    <input
                      type="text"
                      id="Text"
                      placeholder="Enter Text"
                      value={footerVal}
                      onChange={(e)=>setFooterVal(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="WB_template_header">
                    <h5 className="commonHeading">Buttons Optional</h5>
                    <p>
                      {" "}
                      Create buttons that let customers respond to your message
                      or take action
                    </p>
                  </div>
                </div>

                <div className="selectOptionDiv">
                  <div className="row">
                    <div>
                      <div>
                        <SplitButton onOptionSelect={handleOptionSelect} className="save"/>
                      </div>

                      {showCallToAction && (
                        <div className="form_group callToAction mt-3">
                          <h6>Visit Link</h6>
                          <div className="form-inline">
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder="Enter Button Text"
                                id="buttonText"
                                name="buttonText"
                                className="custom-input"
                                maxLength={20}
                                value={visitWebsiteText}
                                onChange={(e)=>setVisitWebsiteText(e.target.value)}
                              />

                              <div
                                className="List_card"
                                style={{
                                  position: "absolute",
                                  bottom: "-16px",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {visitWebsiteText.length}
                                  </span>{" "}
                                  Characters
                                </span>

                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  Max Characters :{" "}
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    20
                                  </span>{" "}
                                </span>
                              </div>
                            </div>

                                <select name="urlType" id="urlType">
                                  <option value="Static">Static</option>
                                  <option value="Dynamic">Dynamic</option>
                                </select>

                            <input
                              type="text"
                              placeholder="Enter your Domain https://domain.com"
                              id="visitWebsiteDomain"
                              value={domain}
                              onChange={(e)=>{setDomain(e.target.value);}}
                            />
                            <button
                              className="actionBtn"
                              onClick={() => {
                                setShowCallToAction(false);
                                setAdditionalUrls([]);
                                setVisitWebsiteText("");
                                setDomain("");
                                setTotalButtons(totalButtons - 1);
                              }}
                            >
                              <DeleteIcon />
                            </button>

                            {additionalUrls.length < 1 && (
                              <button onClick={handleAddUrl} className="save">
                                <AddIcon className="addIcon"/> Add Url
                              </button>
                            )}
                            
                          </div>

                          {additionalUrls.map((url) => (
                            <>
                              <div key={url.id} className="form-inline mt-4">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    id="buttonText"
                                    className="custom-input"
                                    placeholder="Enter Button Text"
                                    maxLength={20}
                                    value={url.buttonText}
                                    onChange={(e) =>
                                      setAdditionalUrls(
                                        additionalUrls.map((u) =>
                                          u.id === url.id
                                            ? {
                                                ...u,
                                                buttonText: e.target.value,
                                              }
                                            : u
                                        )
                                      )
                                    }
                                  />

                                  <div
                                    className="List_card"
                                    style={{
                                      position: "absolute",
                                      bottom: "-16px",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {url.buttonText.length}
                                      </span>{" "}
                                      Characters
                                    </span>

                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      Max Characters :{" "}
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        20
                                      </span>{" "}
                                    </span>
                                  </div>
                                </div>

                                <select name="urlType" id="urlType">
                                  <option value="Static">Static</option>
                                  <option value="Dynamic">Dynamic</option>
                                </select>

                                <input
                                  type="text"
                                  placeholder="Enter Domain https://domain.com"
                                  id="visitWebsiteDomain"
                                  value={url.domain}
                                  onChange={(e) =>
                                    setAdditionalUrls(
                                      additionalUrls.map((u) =>
                                        u.id === url.id
                                          ? { ...u, domain: e.target.value }
                                          : u
                                      )
                                    )
                                  }
                                />
                                <button
                                  className="actionBtn"
                                  onClick={() => handleRemoveUrl(url.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                      )}

                      {showCallPhone && (
                        <div className="form_group callPhone mt-4">
                          <div className="form-inline align-items-end">
                          <div><h6>Call Phone Number</h6>
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder="Enter Caption"
                                className="custom-input phoneInputText"
                                maxLength={20}
                                value={phoneNumberText}
                                onChange={(event) =>
                                  setPhoneNumberText(event.target.value)
                                }
                              />

                              <div
                                className="List_card"
                                style={{
                                  position: "absolute",
                                  bottom: "-16px",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {phoneNumberText.length}
                                  </span>{" "}
                                  Characters
                                </span>

                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  Max Characters :{" "}
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    20
                                  </span>
                                </span>
                              </div>
                            </div></div>
                            <div><h6 className="ms-1">Call Phone Number</h6>
                            <PhoneInput
                              defaultCountry="in"
                              id="visitWebsiteDomain"
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                              className="AddPhoneNumberContain"
                            />
                            </div>
                            <button
                              className="actionBtn"
                              onClick={() => {
                                setShowCallPhone(false);
                                setPhoneNumberText("");
                                setPhone("");
                                setTotalButtons(totalButtons - 1);
                              }}
                            >
                              <DeleteIcon className="redTrash" />
                            </button>
                          </div>
                        </div>
                      )}

                      {showQuickReply && (
                        <div className="form_group quickReply mt-5">
                          <h6>Quick Reply</h6>
                          <div className="form-inline">
                            <div style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder="Enter Quick Reply Text"
                                className="inputQuickReply"
                                maxLength={25}
                                value={quickReply}
                                onChange={(event) =>
                                  setQuickReply(event.target.value)
                                }
                              />

                              <div
                                className="List_card"
                                style={{
                                  position: "absolute",
                                  bottom: "-16px",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {quickReply.length}
                                  </span>{" "}
                                  Characters
                                </span>

                                <span
                                  style={{ fontSize: "8px", marginTop: "0px" }}
                                >
                                  Max Characters :{" "}
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    20
                                  </span>{" "}
                                </span>
                              </div>
                            </div>

                            <button
                              className="actionBtn"
                              onClick={() => {
                                setShowQuickReply((prev) => prev);
                                setQuickReply("");
                                setTotalButtons(totalButtons - 1);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                            {isNewQuickReply.length < 9 && (
                              <button onClick={handleNewReply} className="save">
                                <AddIcon /> Add Reply
                              </button>
                            )}
                          </div>

                          {isNewQuickReply.map((reply) => (
                            <>
                              <div key={reply.id} className="form-inline mt-4">
                                <div style={{ position: "relative" }}>
                                  <input
                                    className="inputQuickReply"
                                    type="text"
                                    placeholder="Enter Button Text"
                                    maxLength={25}
                                    value={reply.buttonText}
                                    onChange={(e) =>
                                      setIsNewQuickReply(
                                        isNewQuickReply.map((r) =>
                                          r.id === reply.id
                                            ? {
                                                ...r,
                                                buttonText: e.target.value,
                                              }
                                            : r
                                        )
                                      )
                                    }
                                  />

                                  <div className="List_card" style={{ position: "absolute", bottom: "-16px", width: "100%"}}>
                                    <span style={{fontSize: "8px", marginTop: "0px"}}>
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {reply.buttonText.length}
                                      </span>
                                      Characters
                                    </span>

                                    <span
                                      style={{
                                        fontSize: "8px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      Max Characters :{" "}
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        20
                                      </span>
                                    </span>
                                  </div>
                                </div>

                                <button
                                  className="actionBtn"
                                  onClick={() => {
                                    setIsNewQuickReply(
                                      isNewQuickReply.filter(
                                        (r) => r.id !== reply.id
                                      )
                                    );
                                    setTotalButtons(totalButtons - 1);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-5">
                  <div className="form_group mt-0">
                    <button type="button"  className='save ' onClick={handleSubmit}>
                      Save
                    </button>
                    <button type="button" className="cancel" onClick={()=>cancelNavigate('/wbtemplate')}>Cancel</button>
                  </div>
                </div>
                {/* Spinner */}
                {loading && (
                  <div className="spinnerOverlay">
                    <ClipLoader loading={loading} size={50} />
                  </div>
                )}
                <ShowSnackBar
                  open={snackBar.open}
                  severity={snackBar.severity}
                  message={snackBar.message}
                  onClose={() => setSnackBar((prevState) => ({ ...prevState, open: false }))}
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="Preview_Contain" >
                <div className="Preview_head">
                  <div className="Preview_user">
                    <img src={User} alt="user icon" />
                  </div>
                  <h4 className="Preview_title">
                    {userInfo ? userInfo : "User Name"}
                  </h4>
                </div>
                <div className="Preview_card">
                  <div className="mt-3 mainBox">
                    {templateType === 'Media' && (
                    <>
                      {selectedMedia === 'Document' && (
                        <div className="showMedia">
                          {isDocument && (
                            <a
                              href={fileURL}
                              className="uploaded-document"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Document
                            </a>
                          )}
                        </div>
                      )}

                      {selectedMedia === 'Video' && (
                        <div className="showVideo">
                          {isVideo && (
                            <video autoPlay controls className="uploaded-video viewMedia">
                              <source src={fileURL} type={fileType} />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </div>
                      )}

                      {selectedMedia === 'Images' && (
                        <div className="showImages">
                          {isImage && (
                            <img
                              src={fileURL}
                              className="uploaded-image viewMedia"
                              alt="Uploaded Preview"
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}

                    <div className="mt-2 templateMessage">
                      
                      <p className="message" dangerouslySetInnerHTML={{ __html: formattedMessage || "Template Message..." }} />
                    </div>
                    <p className="mt-2 templateFooter">
                      {footerVal || "Template Footer..."}
                    </p>
                  </div>
                <div id="previewBtn">
                      {visitWebsiteText !== "" && (
                        <div className="urlButtons ">
                          <button className="btn commonBtn mt-2">
                            <LaunchIcon /> {visitWebsiteText}
                          </button>
                          {additionalUrls.length > 0 &&
                            additionalUrls.map((button, index) => (
                              <button
                                key={index}
                                type="button"
                                className="btn commonBtn mt-2"
                              >
                                <LaunchIcon />{" "}
                                {button.buttonText
                                  ? button.buttonText
                                  : "Button Text"}
                              </button>
                            ))}
                        </div>
                      )}
                      {phoneNumberText !== "" && (
                        <div className="d-flex justify-content-center">
                          <button className="btn commonBtn mt-2">
                            <LocalPhoneIcon /> {phoneNumberText}
                          </button>
                        </div>
                      )}

                      {quickReply !== "" && (
                        <div className="quickReply">
                          <button className="btn commonBtn mt-2">
                            <ReplyIcon /> {quickReply}
                          </button>
                          {isNewQuickReply.length > 0 &&
                            isNewQuickReply.map((button, index) => (
                              <a
                                key={index}
                                href={button.domain ? button.domain : "#"}
                                type="button"
                                className="btn commonBtn mt-2"
                              >
                                <ReplyIcon />
                                {button.buttonText
                                  ? button.buttonText
                                  : "Button Text"}
                              </a>
                            ))}
                        </div>
                      )}

                      <div></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WbAddTemplate;
