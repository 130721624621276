import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/footer';
import WbQuickCampaigns from './pages/WbQuickCampaigns';
import WbCustomizeCampaigns from './pages/WbCustomizeCampaigns';
import WbTemplate from './pages/WbTemplate';
import WbManageMedia from './pages/WbManageMedia';
import WbManageGroups from './pages/WbManageGroups';
import WbManageBlockNumber from './pages/WbManageBlockNumber';
import WbAddTemplate from './pages/WbAddTemplate';
import WbManageDealer from './pages/admin/WbManageDealer';
import WbDevelopersTools from './pages/WbDevelopersTools';
import WbTransactionDetail from './pages/admin/WbTransactionDetail';
import WbDeliveryReport from './pages/WbDeliveryReport';
import WbSessionRecievedReport from './pages/WbSessionRecievedReport';
import WbMisReport from './pages/WbMisReport';
import WbDealerwiseMisReport from './pages/WbDealerwiseMisReport';
import Dashboard from './pages/Dashboard';
import AdminDashboard from './pages/admin/adminDashboard';
import AdminGuard from './guards/AdminGuard';
// import Drag from './pages/Drag'
import WbLoginDetails from './pages/WbLoginDetails';
import Demo from './pages/Demo';
import Login from './pages/Login';
import { useUIContext } from './context';
import AuthGuard from './guards/authGuards';
import { Me } from './services/authServieces';

import CreateFirstPage from './pages/CreateFirstPage';
import AdminPanel from './pages/mainquestion';
import Chatboat from './pages/Chatboat';
import CreateBot from './pages/CreateBot';
import Managebot from './pages/Managebot';

import Profile from './pages/profile';
import Changepassword from './pages/Changepassword';
import ManageWaba from './pages/admin/ManageWaba'


const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Move useNavigate here
  const showNavbarFooter = !['/login','/managebot'].includes(location.pathname);
  const { isSidebarCollapsed } = useUIContext();
  const [userType, setUserType] = useState(null); 




  const excludedPaths = ['/login', '/managebot'];
  const isLoginPage = excludedPaths.includes(location.pathname);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await Me();
        const userType = userData?.data?.user_type
        setUserType(userType); 
        if (location.pathname === '/' && userType === 'admin') {
          navigate('/admin'); 
        }
      } catch (error) {
        navigate('/login'); 
      }
    };

    fetchData();
  }, [navigate]); // Empty dependency array ensures this runs on every component mount

  return (
    <div id="page-body" className={!isSidebarCollapsed ? 'sidebar-collapsed' : ''}>
    <section className={isLoginPage ? '' : 'Contain'} id="Contain">
    {showNavbarFooter && <Header isSidebarCollapsed={isSidebarCollapsed} />}
      <div  className={isLoginPage ? '' : 'Dash_contain'}>
          <Routes>
            {/* Protected Routes */}
            {/* <Route path="/drag" element={<Drag />} /> */}
            <Route path="/" element={<AuthGuard><Dashboard /></AuthGuard>} />
            <Route path="/wbquickcampaigns" element={<AuthGuard><WbQuickCampaigns /></AuthGuard>} />
            <Route path="/wbcustomizecampaigns" element={<AuthGuard><WbCustomizeCampaigns /></AuthGuard>} />
            <Route path="/wbtemplate" element={<AuthGuard><WbTemplate /></AuthGuard>} />
            <Route path="/wbmanagemedia" element={<AuthGuard><WbManageMedia /></AuthGuard>} />
            <Route path="/wbmanagegroups" element={<AuthGuard><WbManageGroups /></AuthGuard>} />
            <Route path="/wbmanageblocknumber" element={<AuthGuard><WbManageBlockNumber /></AuthGuard>} />
            <Route path="/wbaddtemplate" element={<AuthGuard><WbAddTemplate /></AuthGuard>} />
            <Route path="/wbdeveloperstools" element={<AuthGuard><WbDevelopersTools /></AuthGuard>} />
            <Route path="/wbdeliveryreport" element={<AuthGuard><WbDeliveryReport /></AuthGuard>} />
            <Route path="/wbsessionrecievedreport" element={<AuthGuard><WbSessionRecievedReport /></AuthGuard>} />
            <Route path="/wbmisreport" element={<AuthGuard><WbMisReport /></AuthGuard>} />
            <Route path="/wbdealerwisemisreport" element={<AuthGuard><WbDealerwiseMisReport /></AuthGuard>} />
            <Route path="/wblogindetails" element={<AuthGuard><WbLoginDetails /></AuthGuard>} />
            <Route path="/demo" element={<AuthGuard><Demo /></AuthGuard>} />


            {/* Admin Routes*/}
            <Route path="/admin" element={<AuthGuard><AdminGuard><AdminDashboard /></AdminGuard></AuthGuard>} />
            <Route path="/admin/transaction-details" element={<AuthGuard><AdminGuard><WbTransactionDetail /></AdminGuard></AuthGuard>} />
            <Route path="/admin/manage-dealer" element={<AuthGuard><AdminGuard><WbManageDealer /></AdminGuard></AuthGuard>} />
            <Route path="/admin/manage-waba" element={<AuthGuard><AdminGuard><ManageWaba/></AdminGuard></AuthGuard>} />
            {/* <Route path="/admin/manage-waba" element={<AuthGuard></AuthGuard>} /> */}


            <Route path="/createbot" element={<CreateBot/>} />
            <Route path="/createfirstPage" element={<CreateFirstPage />} />
            <Route path="/mainquestion" element={<AdminPanel />} />
            <Route path="/chatboat" element={<Chatboat />} />
            <Route path="/managebot" element={<Managebot />} />

            <Route path="/profile" element={<AuthGuard><Profile/></AuthGuard>} />
            <Route path="/changepassword" element={<AuthGuard><Changepassword/></AuthGuard>} />
            
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </section>
      {showNavbarFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
};

export default App;
